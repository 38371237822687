import { Activity, User } from "myfitworld-model";
import React, { createContext, useContext, useEffect, useState } from "react";
import { useUserProvider } from "./UserProvider";
import { firestore } from "../firebase";
import { getSnapshot } from "../api/helpters";

export const MessagesContext = createContext<{
    unreadCount:number,
    saveMessage: (message: Activity) => void,
    messagesForEachClient: ChatsMap | undefined,
    markAsReadMessagesForClient: (userId: string) => void,
    currentLimit: number,
    setCurrentLimit: any,
    setUserIdForLoad: any
}|undefined>(undefined);

export interface Conversation {
  messages: Activity[];
  unread: number;
}

export interface ChatsMap {
  [conversationId: string]: Conversation;
}
const limit:number = 10;

export const MessagesProvider = ({ children }: {children: any}) => {
  const [unreadCount, setUnreadCount] = useState(0);
  const [messagesForEachClient, setMessagesForEachClient] = useState<ChatsMap | undefined>(undefined);
  const [currentLimit, setCurrentLimit] = useState(10);
  const [userIdForLoad, setUserIdForLoad] = useState<string>();
  const {user} = useUserProvider();

  const prepMessage = (doc: firebase.firestore.DocumentData) => {
      return {
        ...doc.data(),
        createdAt: new Date(doc.data().createdAt !== undefined ? doc.data().createdAt.seconds * 1000 : doc.data().message.createdAt.seconds * 1000),
        id: doc.id,
        read: doc.data().read,
      } as Activity;
  };

  useEffect(() => {
    setMessagesForEachClient(undefined);
    readMessagesForEachUser();
  }, [user]);

  useEffect(() => {
    if (user !== undefined && user !== null) {
      const unsubscribe = firestore
        .collection("activity")
        .where("threadId", "==", user?.id)
        .where("read", "==", false)
        .where("type", "==", "MESSAGE")
        .orderBy("createdAt", "desc")
        .onSnapshot((querySnapshot) => {
          querySnapshot.docChanges().forEach((change) => {
            if (change.type === "added") {
              readMessagesForEachUser();
            }
          });
        });

      return () => setMessagesForEachClient({});
    }
  }, [user])

  useEffect(() => {
    readEarlyMessages();
  }, [currentLimit, userIdForLoad]);

  const readEarlyMessages = async () => {
    if(userIdForLoad !== undefined && messagesForEachClient!== undefined){
      const queryFrom = await firestore
        .collection('activity')
        .where('type', '==', 'MESSAGE')
        .where('senderId', '==', userIdForLoad)
        .where('threadId', '==', user?.id)
        .orderBy('createdAt', 'desc')
        .limit(currentLimit);
      const queryTo = await firestore
        .collection('activity')
        .where('type', '==', 'MESSAGE')
        .where('senderId', '==', user?.id)
        .where('threadId', '==', userIdForLoad)
        .orderBy('createdAt', 'desc')
        .limit(currentLimit);
      
      const messages = await getDataForQueries(queryFrom, queryTo);
      let messagesForUser = messagesForEachClient[userIdForLoad];
      messagesForUser.messages = messages[userIdForLoad].messages;
      setMessagesForEachClient((prevState) => ({...prevState, [userIdForLoad]: messagesForUser}));
    }
  };

  const readMessagesForEachUser = async () => {
    if(user !== undefined && user !== null){
        const trainersAndAdmins = await getTrainersAndAdminsForOrganization();
        const clients = await getClients();
        const allUsers = [...trainersAndAdmins, ...clients];
        let messagesData: { [id: string]: { unread: number; messages: Activity[] } } = {};
        const numberOfLists = allUsers.length % 10 === 0 ? allUsers.length / 10 : Math.ceil(allUsers.length / 10);
        for(let i=1; i <= numberOfLists; i++) {
          let listOfUsers = allUsers.slice((i-1)*10);
          if(i < numberOfLists){
            listOfUsers = allUsers.slice((i-1)*10, i*10);
          } 
          const result = await loadMessagesForUsers(listOfUsers);
          messagesData = Object.assign({}, messagesData, result);
        }

        
        for(const userId of allUsers){
          if(!messagesData[userId]){
            messagesData[userId] = {
              messages: [],
              unread: 0
            }
          }
        }
        const filteredMessages = Object.keys(messagesData).filter((userId) => messagesData[userId].unread !== 0);
        const sumOfUnread = filteredMessages.reduce((total, userId: string) => total + messagesData[userId].unread, 0);
        setUnreadCount(sumOfUnread);
        setMessagesForEachClient(messagesData);
    }
  };

  const loadMessagesForUsers = async (listOfUsers: string[]) => {

    const queryFrom = await firestore
      .collection('activity')
      .where('type', '==', 'MESSAGE')
      .where('senderId', 'in', listOfUsers)
      .where('threadId', '==', user?.id)
      .orderBy('createdAt', 'desc')
      .limit(limit);
    
    const queryTo = await firestore
      .collection('activity')
      .where('type', '==', 'MESSAGE')
      .where('senderId', '==', user?.id)
      .where('threadId', 'in', listOfUsers)
      .orderBy('createdAt', 'desc')
      .limit(limit);

  return await getDataForQueries(queryFrom, queryTo);
};

const getDataForQueries = async (queryFrom: firebase.firestore.Query<firebase.firestore.DocumentData>, queryTo: firebase.firestore.Query<firebase.firestore.DocumentData>) => {
  const query1Promise = new Promise(async (resolve, reject) => {
    try {
      const snapshot1 = await getSnapshot(queryFrom);
      const messagesData1: { [id: string]: { unread: number; messages: Activity[] } } = {};
  
      snapshot1.docs.forEach((change) => {
        const userId = change.data().senderId;
  
        if (messagesData1[userId]) {
          let messages = messagesData1[userId].messages;
          messages.push(prepMessage(change));
          messagesData1[userId].messages = messages;
  
          if (!change.data().read) {
            messagesData1[userId].unread++;
          }
        } else {
          messagesData1[userId] = {
            messages: [prepMessage(change)],
            unread: change.data().read ? 0 : 1,
          };
        }
      });
  
      resolve(messagesData1);
    } catch (error) {
      reject(error);
    }
  });
  
  
  const query2Promise = new Promise(async (resolve, reject) => {
    try {
      const snapshot2 = await getSnapshot(queryTo);
      const messagesData2: { [id: string]: { unread: number; messages: Activity[] } } = {};
  
      snapshot2.docs.forEach((change) => {
        const userId = change.data().threadId;
  
        if (messagesData2[userId]) {
          let messages = messagesData2[userId].messages;
          messages.push(prepMessage(change));
          messagesData2[userId].messages = messages;
        } else {
          messagesData2[userId] = {
            messages: [prepMessage(change)],
            unread: 0,
          };
        }
      });
  
      resolve(messagesData2);
    } catch (error) {
      reject(error);
    }
  });
  
  const messagesFrom:{ [id: string]: { unread: number; messages: Activity[] } }[]  = await Promise.all([query1Promise as Promise<{ [id: string]: { unread: number; messages: Activity[] } }>]);
  const messagesTo:{ [id: string]: { unread: number; messages: Activity[] } }[] = await Promise.all([query2Promise as Promise<{ [id: string]: { unread: number; messages: Activity[] } }>]);
  let mergedMessages = mergeMessages(messagesFrom[0], messagesTo[0]);
  for(const userId of Object.keys(mergedMessages)){
    let messages = mergedMessages[userId].messages;
    messages.sort((a: Activity, b: Activity) => a.createdAt.getTime() - b.createdAt.getTime());
    mergedMessages[userId].messages = messages;
  }
  return mergedMessages;
};

const mergeMessages = (messagesFrom: { [id: string]: { unread: number; messages: Activity[] } },messagesTo: { [id: string]: { unread: number; messages: Activity[] } }) => {
  for(const key of Object.keys(messagesTo)){
    if(messagesFrom[key]){
      let messages = messagesFrom[key];
      messages.messages = [...messages.messages, ...messagesTo[key].messages];
      messages.unread = messages.unread + messagesTo[key].unread;
      messagesFrom[key] = messages;
    } else {
      messagesFrom[key] = messagesTo[key];
    }
  }
  return messagesFrom;
};

const getTrainersAndAdminsForOrganization = async () => {
  let trainersAndAdmins: string[] = [];
  if(user?.currentOrganization !== undefined){
    const snap = await firestore
          .collection('organizationUser')
          .where('orgId', '==', user?.currentOrganization)
          .where('role', 'in', ['Admin', 'Trainer', 'AssistantTrainer']).get();
    snap.forEach(s => {
      if(s.data().userId !== user?.id){
        trainersAndAdmins.push(s.data().userId);
      }
    }); 
  }

  return trainersAndAdmins;
};

  const getClients = async () => {
    let clients: string[] = [];
    if(user?.currentOrganization !== undefined){
      const snap = await firestore
            .collection('organizationUser')
            .where('orgId', '==', user?.currentOrganization)
            .where('role', '==', 'Client').get();
      snap.forEach(s => {
        clients.push(s.data().userId);
      }); 
    }

    return clients;
  }

  const markAsReadMessagesForClient = async (clientId: string) => {

    if(messagesForEachClient && Object.keys(messagesForEachClient).length > 0 && messagesForEachClient[clientId] && messagesForEachClient[clientId].unread > 0) {
      let messForClient = messagesForEachClient[clientId];
      messagesForEachClient[clientId].messages.map(async (message) => {
        await firestore.collection('activity').doc(message.id)
          .update({read: true});
      });
      const unread = messForClient.unread;
      unreadCount - unread >=0 && setUnreadCount((prevState) => prevState - unread);
      messForClient.unread = 0;

      setMessagesForEachClient((prevState) => ({...prevState, [clientId]: messForClient}));
    } 
  };

  const saveMessage = async (message: Activity) => {

    firestore.collection('activity').add(message).then(
      (doc) => {
       if(messagesForEachClient){
          let messagesForUser = messagesForEachClient[message.threadId];
          messagesForUser.messages.push(({...message, id: doc.id}));
          setMessagesForEachClient((prevState) => ({
            ...prevState,
            [message.threadId]: messagesForUser, 
          }));
      }
      }
    )
  };
    
  return (
    <MessagesContext.Provider
      value={{
        unreadCount,
        messagesForEachClient,
        markAsReadMessagesForClient,
        saveMessage,
        currentLimit,
        setCurrentLimit,
        setUserIdForLoad
      }}
    >
    {children}
    </MessagesContext.Provider>
  );
};

export const useMessagesContext = () => {
    const context = useContext(MessagesContext);
    if (context === undefined) {
      throw new Error(
        'useNotificationsContext must be used within a NotificationsProvider'
      );
    }
    return context;
};