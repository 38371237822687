import React, {Fragment, useCallback, useEffect, useState} from "react";
import {SideDrawerProperties} from "../SideDrawer";
import {useUserProvider} from "../../providers/UserProvider";
import ChatDialog from "../chat/ChatDialog";
import {Box, createStyles, makeStyles, Typography} from "@material-ui/core";
import { ChatsMap, useMessagesContext } from "../../providers/MessagesProvider";
import LastMessage from "./LastMessage";
import useSortConversations from "../../providers/useSortConversations";
import CustomMessageSideDrawer from "../CustomMessageSideDrawer";
import PreferenceChatDialog, { ChatPreferenceOptionsProps } from "../chat/PreferenceChatDialog";
import { useIntl } from "react-intl";
import messages from "./messages"
import LoadingSpinner from "../LoadingSpinner";
import { getUser } from "src/api/usersApi";
import { Activity, User } from "myfitworld-model";

const useStyles = makeStyles(() =>
  createStyles({
    messageBox:{
      marginTop:'20px',
    },
    noMessagesText: {
      marginTop: 100,
      textAlign: 'center'
    }
  })
);

const MessagesDrawer = ({ isOpen, onClose }: Props) => {
  // Universal consts:
  const { user } = useUserProvider();
  const messageCtx = useMessagesContext();
  const {formatMessage} = useIntl();
  const classes = useStyles();

  const [isOpenFullChat, setOpenFullChat] = useState(false);
  const [isPreferenceChatDialog, setChatPreferenceDialog] = useState<{isOpen: boolean, selectedProp: ChatPreferenceOptionsProps | undefined}>({isOpen: false, selectedProp:undefined});

  const [dialogThreadId, setDialogThreadId] = useState<string>();
  const [dialogThreadName, setDialogThreadName] = useState<string>();
  
  const {regularChats, archivedChats} = useSortConversations({allChats: messageCtx.messagesForEachClient, messagePreferences:user?.messagePreferences})
  const [showArchived, setShowArchived] = useState<boolean>(false);
  const [regularChatsWithClientsData, setRegularChatsWithClientsData] = useState<{[conversationId: string]: {messages: Activity[], unread: number, user: User|undefined}}>({});
  const [archivedChatsWithClientsData, setArchivedChatsWithClientsData] = useState<{[conversationId: string]: {messages: Activity[], unread: number, user: User|undefined}}>({});
  const [loading, setLoading] = useState(true);
  const handleDialogOpen = (userId: string, userName: string) => {
    setDialogThreadId(userId);
    setDialogThreadName(userName);
    setOpenFullChat(true);
  };
  function handleClosePreferenceChatDialog() {
    setChatPreferenceDialog({isOpen: false, selectedProp:undefined});
  }
  
  let options = [showArchived ? {label:formatMessage(messages.unarchive_chat), action: "UNARCHIVE_CHAT"}:{label: formatMessage(messages.archive_chat), action:"ARCHIVE_CHAT"}, {label:formatMessage(messages.delete_chat), action:"DELETE_CHAT"}];
  const handleDialogClose = useCallback((event: any) => {
    event.stopPropagation();
    setOpenFullChat(false);
  }, []);

  useEffect(() => {
    if (!user) return;
  }, [user]);

  const loadUsersData = async (chats: ChatsMap) => {
    const updatedChats = await Promise.all(
      Object.entries(chats).map(async ([key, value]) => {
        const client = await getUser(key);
        return {
          [key]: {
            messages: value.messages,
            unread: value.unread,
            user: client,
          }
        };
      })
    );
    const mergedChats = Object.assign({}, ...updatedChats);
    return mergedChats;
  }

    const loadUserDataForAllChats = async () => {
      setLoading(true);
      if (regularChats === undefined && archivedChats === undefined) {
        setLoading(false);
        return;
      }
    
      const updatedRegularChats = regularChats && await loadUsersData(regularChats);
      const updatedArchivedChats = archivedChats && await loadUsersData(archivedChats);

      setRegularChatsWithClientsData(updatedRegularChats);
      setArchivedChatsWithClientsData(updatedArchivedChats);
      setLoading(false);
    };

    useEffect(() => {
      loadUserDataForAllChats();
    }, [regularChats, archivedChats])
    
  return (
    <Fragment>
      <CustomMessageSideDrawer 
        isOpen={isOpen} 
        onClose={onClose} 
        showArchived={showArchived} 
        setShowArchived={setShowArchived} >

        {loading && <LoadingSpinner/>}
        {regularChats !== undefined && archivedChats !== undefined && Object.entries(regularChats).length === 0 && Object.entries(archivedChats).length === 0 &&
          <Typography variant='h3' className={classes.noMessagesText}>{formatMessage(messages.no_messages)}</Typography>
        }
        {archivedChats && regularChats && messageCtx.messagesForEachClient !== undefined &&
          <Box className={classes.messageBox}>
          {
            Object.entries(showArchived ? archivedChatsWithClientsData : regularChatsWithClientsData).map(([key, value]) => {
              return (
                value.messages.length !== 0 && 
                <LastMessage 
                  key={key} 
                  userId={key} 
                  message={value.messages[value.messages.length - 1]} 
                  unread={value.unread} 
                  onClick={handleDialogOpen} 
                  setChatPreferenceDialog={setChatPreferenceDialog} 
                  options = {options}
                  user={value.user}
                />
              )
            }) 
          }
        </Box>} 
      </CustomMessageSideDrawer>
      
      {/* Chat with messages dialog: */}
      {isOpenFullChat && dialogThreadId && (
        <ChatDialog isOpen={isOpenFullChat} handleClose={handleDialogClose} userId={dialogThreadId} userName={dialogThreadName} ignoreTabs={true}/>
      )}

      {/* Archive/Unarchive or delete 'Are you sure' Dialog */}
       {isPreferenceChatDialog.isOpen && isPreferenceChatDialog.selectedProp &&  (
        <PreferenceChatDialog isOpen = {isPreferenceChatDialog.isOpen} handleClose = {handleClosePreferenceChatDialog} chatPreferenceOptions = {isPreferenceChatDialog.selectedProp} />
      )}
    </Fragment>
  );
};

export default MessagesDrawer;

interface Props extends SideDrawerProperties {}

