import { Box, Theme, ThemeProviderProps, Typography, createStyles, makeStyles, useMediaQuery } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useUserProvider } from '../../providers/UserProvider';
import { Activity } from 'myfitworld-model';
import { DoneAll } from '@material-ui/icons';
import { useMessagesContext } from '../../providers/MessagesProvider';
import LoadingSpinner from '../LoadingSpinner';
import LoadMoreButton from './LoadMoreButton';
import { format } from 'date-fns';
import { useMfwThemeProvider } from 'myfitworld-utils';
import { ProviderThemeType } from 'myfitworld-utils/dist/types/ThemeTypes';
import getLocalizedProperty from 'src/utils/getLocalizedProperty';
import ReactionImage from '../ReactionImage';
import { useIntl } from 'react-intl';
import chatMessages from './messages';

const createStylesForTheme = (theme: ProviderThemeType, isMobile: boolean) =>
  makeStyles(() =>
    createStyles({
        incomingMessageBox: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            backgroundColor: theme.theme.backgroundColor.light,
            width: isMobile ? '80%' : '30%',
            marginBottom: '1%',
            padding: '1%',
            borderRadius: 5,
        },
        sendingMessageBox:{
            display:'flex',
            flexDirection:'column',
            marginBottom: '2%',
            backgroundColor: theme.theme.primary,
            padding:'1%', 
            alignItems:"end",
        },
        messageText: {
            fontSize: 15, 
            whiteSpace: 'pre-wrap', 
            wordWrap: 'break-word'
        },
        replyMessageBox: {
            backgroundColor: theme.theme.backgroundColor.light,
            borderRadius: '5px 5px 0 0',
            border: '1px solid',
            borderColor: theme.theme.primary,
            padding: '1%'
        },
        replyMessageBoxText: {
            fontSize: 13
        },
        borderRadiusWhenReply: {
            borderRadius: '0 0 5px 5px'
        },
        createdAtText: {
            fontSize: 10,
            marginRight: 2
        }
    })
  );

const MessagesView = ({userId, setIsLoadMore} : {userId:string, setIsLoadMore: (state: boolean) => void}) => {
    const {user} = useUserProvider();
    const messagesCtx = useMessagesContext();
    const theme = useMfwThemeProvider();
    const isMobile = useMediaQuery(theme.mfwMuiWebTheme.breakpoints.down('sm'));
    const useStyles = createStylesForTheme(theme.theme, isMobile);
    const classes = useStyles();
    const {formatMessage, locale} = useIntl();
    
    useEffect(() => {
        if(messagesCtx.messagesForEachClient && Object.keys(messagesCtx.messagesForEachClient).length !== 0){
            messagesCtx.messagesForEachClient[userId].messages.sort((a: Activity, b: Activity) => a.createdAt.getTime() - b.createdAt.getTime());
        }
    },[messagesCtx.messagesForEachClient]);

    const loadEarlierMessages = () => {
        setIsLoadMore(true);
        messagesCtx.setCurrentLimit((prevState: number) => prevState + 10);
        messagesCtx.setUserIdForLoad(userId);
    }

    const getTime = (dateTime: Date) => {

        return format(dateTime, "dd-MM-yyyy HH:mm");
    }
 
    return(
        <Box>
            {
                messagesCtx.messagesForEachClient && Object.keys(messagesCtx.messagesForEachClient).length === 0 && 
                <LoadingSpinner/>
            }
            {
                messagesCtx.messagesForEachClient && messagesCtx.messagesForEachClient[userId] && messagesCtx.messagesForEachClient[userId].messages.length >= messagesCtx.currentLimit && 
                <LoadMoreButton onPress={loadEarlierMessages} />
            }
            {messagesCtx.messagesForEachClient && Object.keys(messagesCtx.messagesForEachClient).length !== 0 && messagesCtx.messagesForEachClient[userId].messages.map((message: Activity) => 
                (
                    <>
                        {message.senderId === user?.id && 
                            <Box display='flex' justifyContent='flex-end' key={message.id}>
                                <Box flexDirection={'column'} width={ isMobile ? '80%' : '30%'}>
                                {message.replyOnWorkoutFeedbackData && 
                                    <Box className={classes.replyMessageBox}>
                                        <Box display='flex' flexDirection='column' justifyContent='space-between'>
                                            <Box display='flex' flexDirection='row'>
                                                { message.replyOnWorkoutFeedbackData.reaction && <ReactionImage reaction={message.replyOnWorkoutFeedbackData.reaction} smallSize={true}/>}
                                                <Typography variant="h5" style={{marginLeft: 5}} className={classes.replyMessageBoxText}>{formatMessage(chatMessages.workout_completed).toUpperCase()}</Typography>
                                            </Box>
                                            {message.replyOnWorkoutFeedbackData?.message?.workout?.title &&
                                                <Typography className={classes.replyMessageBoxText}>{getLocalizedProperty(locale, message.replyOnWorkoutFeedbackData.message.workout?.title).length > 6 ? `${getLocalizedProperty(locale, message.replyOnWorkoutFeedbackData?.message?.workout?.title).slice(0, 6)}...` : getLocalizedProperty(locale, message.replyOnWorkoutFeedbackData?.message?.workout?.title)}</Typography>
                                            }
                                        </Box>
                                        <Typography className={classes.replyMessageBoxText}>{message.replyOnWorkoutFeedbackData.text}</Typography>
                                    </Box>
                                }
                                <Box className={classes.sendingMessageBox} borderRadius={message.replyOnWorkoutFeedbackData ? '0 0 5px 5px' : 5}>
                                    <Typography className={classes.messageText}>{message.text}</Typography>
                                    <Box flexDirection={'row'} display='flex' justifyContent='flex-end'>
                                        <Typography className={classes.createdAtText}>{getTime(message.createdAt)}</Typography>
                                        <DoneAll color={message.read ? 'primary' : 'disabled'} style={{fontSize: 15}}/>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                        }

                        {message.senderId === userId && 
                            <Box className={classes.incomingMessageBox} key={message.id}>
                                <Typography className={classes.messageText}>{message.text}</Typography>
                                <Typography className={classes.createdAtText}>{getTime(message.createdAt)}</Typography>
                            </Box>
                        }
                    </>
                )
            )}
        </Box>
    );
};
export default MessagesView;
