import {  defineMessages } from 'react-intl'

const messages = defineMessages({
  archive_chat: {
    id: 'button.archive_chat',
    defaultMessage: 'Archive Chat'
  },
  unarchive_chat: {
    id: 'button.unarchive_chat',
    defaultMessage: 'Unrchive Chat'
  },
  delete_chat: {
    id: 'button.delete_chat',
    defaultMessage: 'Delete Chat'
  },
  archive_chat_questions: {
    id: 'chat.archive_chat_questions',
    defaultMessage: 'Are you sure you want to archive this chat? '
  },
  unarchive_chat_questions: {
    id: 'chat.unarchive_chat_questions',
    defaultMessage: 'Are you sure you want to unarchive this chat? '
  },
  delete_chat_questions: {
    id: 'chat.delete_chat_questions',
    defaultMessage: 'Are you sure you want to delete this chat? '
  },
  no_notifications: {
    id: 'no_notifications',
    defaultMessage: "You don't have notifications"
  },
  no_messages: {
    id: 'no_messages',
    defaultMessage: "You don't have messages."
  },
  when_switch_on: {
    id: 'when_switch_on',
    defaultMessage: "When the switch is on, the “i” button will appear for every feature with detailed explanation"
  },
  info_buttons: {
    id: 'info_buttons',
    defaultMessage: "Info buttons"
  },
  info_tutorials: {
    id: 'info_tutorials',
    defaultMessage: "Turn on the tutorials"
  },
  help_section: {
    id: 'help_section',
    defaultMessage: "SHelp Section"
  },
  schedule_consultations: {
    id: 'schedule_consultations',
    defaultMessage: "Schedule Consultation"
  },
  schedule_free_consultations: {
    id: 'schedule_free_consultations',
    defaultMessage: "Click here to schedule a free consultation"
  },
  info_schedule_consultations: {
    id: 'info_schedule_consultations',
    defaultMessage: "Schedule a free consultation through Calendly link"
  },
  contact_support: {
    id: 'contact_support',
    defaultMessage: "Contact Support"
  },
  subject_required: {
    id: 'subject_required',
    defaultMessage: "Subject is required field."
  },
  message_required: {
    id: 'message_required',
    defaultMessage: "Message is required field."
  },
  your_message: {
    id: 'your_message',
    defaultMessage: "Your Message"
  },
  your_subject: {
    id: 'your_subject',
    defaultMessage: "Subject"
  },
  send_message: {
    id: 'send_message',
    defaultMessage: "Send Message"
  },
})

export default messages;
