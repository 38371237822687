
import { Activity,  } from "myfitworld-model";
import { updateActivityUnlinkQuestion } from "src/api/activityApi";
import { deleteNutritionForClient } from "src/api/nutritionApi";
import { deleteStatisticForClient } from "src/api/statisticsApi";
import { deleteTrainerNotificationForClient } from "src/api/trainerNotificationsApi";
import { deleteWorkoutsForClient } from "src/api/workoutsApi";

function useKeepClientWorkouts() {
    const confirmKeepWorkouts = async (activity: Activity) => {
        await updateActivityUnlinkQuestion(activity, 'accepted');
    }

    const rejectKeepWorkouts = async (activity: Activity, orgId: string) => {
        await updateActivityUnlinkQuestion(activity, 'rejected');
        await deleteWorkoutsForClient(activity.senderId, orgId);
        await deleteNutritionForClient(activity.senderId, orgId);
        await deleteTrainerNotificationForClient(activity.senderId);
        await deleteStatisticForClient(activity.senderId);
    }

    return {
        confirmKeepWorkouts,
        rejectKeepWorkouts
    };
}
export default useKeepClientWorkouts;
