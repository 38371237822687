import { UserNutritionPlan } from "myfitworld-model";
import { firestore } from "src/firebase";


export const deleteNutritionForClient = async(userId: string, orgId: string) => {
    firestore
      .collection(`users/${userId}/assignedNutritionPlans`)
      .get()
      .then((nutritionsQuery) => {
        const nutritionPlans: UserNutritionPlan[] = [];

        nutritionsQuery.docs.forEach(val => {
          const raw = val.data();
          nutritionPlans.push({ id: val.id, ...raw } as UserNutritionPlan);
        });
        nutritionPlans.map(async (plan) => {
            const assignedMealsInDaysSnapshot = await firestore.collection(`users/${userId}/assignedNutritionPlans/${plan.id}/assignedMealsInDays`).get();
            assignedMealsInDaysSnapshot.docs.map((doc) => {
               doc.ref.delete();
            });
        });
    })
    .catch(error => {
        console.error(error.message);
    });
}