import { Activity } from "myfitworld-model";
import React, { createContext, Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import { useUserProvider } from "./UserProvider";
import { firestore } from "../firebase";
const _ = require("lodash");

export const NotificationsContext = createContext<{
    allNotifications:Activity[],
    unreadCount:number,
    notificationsForEachClient: {[id:string]: {unread: number, notifications: Activity[]}},
    markAsReadAllNotifications: () => void,
    markAsReadNotificationsForClient: (userId: string) => void,
    limit: number, 
    setLimit: any,
    setCurrentLimit: any,
    currentLimit: number,
    setUserIdForLoad: any,
    deleteFromListOfNotifications: () => void;
    readAllNotifications: () => Promise<void>;
}|undefined>(undefined);

export const NotificationsProvider = ({ children }: {children: any}) => {
    const [allNotifications, setAllNotifications] = useState<Activity[]>([]);
    const [unreadCount, setUnreadCount] = useState(0);
    const [notificationsForEachClient, setNotificationsForEachClient] = useState<{[id:string]: {unread: number, notifications: Activity[]}}>({});
    const [limit, setLimit] = useState(20);
    const {user} = useUserProvider();
    const [currentLimit, setCurrentLimit] = useState(10);
    const [userIdForLoad, setUserIdForLoad] = useState<string>();
    const [updated, setUpdated] = useState(false);
    //notifications for birthday readTrainer ...
    //notifications for daily activity
    //notifications for workout
    const prepMessage = (doc: firebase.firestore.DocumentData, duplicateRead: boolean) => {
        return {
          ...doc.data(),
          createdAt: new Date(doc.data().createdAt !== undefined ? doc.data().createdAt.seconds * 1000 : doc.data().message.createdAt.seconds * 1000),
          id: doc.id,
          read: duplicateRead ? doc.data().readTrainer : doc.data().read,
        } as Activity;
    };

    useEffect(() => {
        readAllNotifications();
        readNotificationsForEachUser();
        return () => {
            setAllNotifications([]);
            setNotificationsForEachClient({});
        }
    }, [user, limit, unreadCount, updated]);

    useEffect(() => {
        if (user !== undefined && user !== null) {
          firestore
            .collection("activity")
            .where("watchers", "array-contains", user?.id)
            .where('read', '==', false)
            .where('type', 'in', ['ACTIVITY', 'BIRTHDAY_NOTIFICATION', 'DELETE_USER', 'TRAINER_NOTIFICATION', 'UNLINK_QUESTION', 'UNLINK_NOTIFICATION'])
            .where('createdAt', '<=', new Date())
            .orderBy("createdAt", "desc")
            .onSnapshot((querySnapshot) => {
              querySnapshot.docChanges().forEach((change) => {
                readAllNotifications();
              });
            });
        }
        return () => setAllNotifications([]); 
      }, [user])

    useEffect(() => {
        readEarlyNotifications();
        return () => setAllNotifications([]);
    }, [currentLimit, userIdForLoad]);


    const readEarlyNotifications = async () => {
        let notifications: Activity[] = [];
        if(userIdForLoad !== undefined){
            const snapshot = await firestore
                .collection('activity')
                .where("watchers", "array-contains", user?.id)
                .where('threadId', '==', userIdForLoad)
                .where('type', 'in', ['ACTIVITY', 'BIRTHDAY_NOTIFICATION', 'DELETE_USER', 'TRAINER_NOTIFICATION', 'UNLINK_QUESTION', 'UNLINK_NOTIFICATION'])
                .where('createdAt', '<=', new Date())
                .orderBy("createdAt", "desc")
                .limit(currentLimit).get();
    
            snapshot.forEach((doc) => {
                notifications.push(prepMessage(doc, (doc.data() as Activity).type in ['BIRTHDAY_NOTIFICATION', 'TRAINER_NOTIFICATION']));
            });
            let notificationsForUser = notificationsForEachClient[userIdForLoad];
            notificationsForUser.notifications = notifications;
            setNotificationsForEachClient((prevState) => ({...prevState, [userIdForLoad]: notificationsForUser}));
        }
    };

    const readAllNotifications = async() => {
        let newUnreadCount = 0;
        let notificationsData:Activity[] = [];
        if(user !== undefined && user !== null){
            await firestore
            .collection('activity')
            .where("watchers", "array-contains", user?.id)
            .where('type', 'in', ['BIRTHDAY_NOTIFICATION', 'ACTIVITY', 'DELETE_USER', 'TRAINER_NOTIFICATION', 'UNLINK_QUESTION', 'UNLINK_NOTIFICATION'])
            .where('createdAt', '<=', new Date())
            .orderBy('createdAt', 'desc')
            .limit(limit)
            .onSnapshot((snapshot) => {
                snapshot.docChanges().map((change) => {
                    notificationsData.push(prepMessage(change.doc, false));
                    notificationsData = sortNotifications(notificationsData);
                });
                notificationsData.forEach(notification => {
                    if((notification.read !== undefined && !notification.read) || (notification.readTrainer !== undefined && !notification.readTrainer)){
                        newUnreadCount++;
                    }
                })
                setUnreadCount(newUnreadCount);
                setAllNotifications(notificationsData);
            });
        }
    }

    const sortNotifications = (notificationsData: Activity[]) => {
        notificationsData.sort((a, b) => b.createdAt.getTime() - a.createdAt.getTime());
        notificationsData = _.uniqBy(notificationsData, "id");
        return notificationsData;
    }

    const readNotificationsForEachUser = async () => {
        if(user !== undefined && user !== null){
            const trainersAndAdmins = await getTrainersAndAdminsForOrganization();
            const clients = await getClients();
            const notificationsForTrainersAndAdmins = await loadNotificationsForUsers(trainersAndAdmins);
            const notificationsForClients = await loadNotificationsForUsers(clients);

            const notificationsDataForEach = Object.assign({}, notificationsForTrainersAndAdmins[0], notificationsForClients[0]);
            setNotificationsForEachClient(notificationsDataForEach);
        }
    }

    const loadNotificationsForUsers = async(listOfUsers: string[]) => {
        let notificationsDataForEach: {[id:string]: {unread: number, notifications: Activity[]}} = {};
        const promises = listOfUsers.map(async (userId) => {
            const snapshot1 = await firestore
            .collection('activity')
            .where('threadId', '==', userId)
            .where('type', 'in', ['ACTIVITY', 'BIRTHDAY_NOTIFICATION', 'DELETE_USER', 'TRAINER_NOTIFICATION', 'UNLINK_QUESTION', 'UNLINK_NOTIFICATION'])
            .where("watchers", "array-contains", user?.id)
            .where('createdAt', '<=', new Date())
            .orderBy("createdAt", "desc")
            .limit(currentLimit).get();
            if(snapshot1.empty){
                notificationsDataForEach[userId] = {
                    notifications: [],
                    unread: 0
                };
            }
            snapshot1.forEach((doc) => {
              if(notificationsDataForEach[doc.data().threadId]){
                let notifications = notificationsDataForEach[doc.data().threadId].notifications;
                notifications.push(prepMessage(doc, (doc.data() as Activity).type in ['BIRTHDAY_NOTIFICATION', 'TRAINER_NOTIFICATION']));
                notificationsDataForEach[doc.data().threadId].notifications = notifications;
                if(!doc.data().read){
                    notificationsDataForEach[doc.data().threadId].unread++;
                }
            } else {
                notificationsDataForEach[doc.data().threadId] = {
                    notifications: [prepMessage(doc, (doc.data() as Activity).type in ['BIRTHDAY_NOTIFICATION', 'TRAINER_NOTIFICATION'])],
                    unread: doc.data().read || doc.data().readTrainer ? 0 : 1
                }
            }
            });
            return notificationsDataForEach;
        });

        return await Promise.all(promises);
    }

    const readNotifications = async() => {
        if(user !== undefined && user !== null){
            const trainersAndAdmins = await getTrainersAndAdminsForOrganization();
            const clients = await getClients();
              firestore
              .collection('activity')
              .where("watchers", "array-contains", user?.id)
              .where('type', 'in', ['BIRTHDAY_NOTIFICATION', 'ACTIVITY', 'DELETE_USER', 'TRAINER_NOTIFICATION', 'UNLINK_QUESTION', 'UNLINK_NOTIFICATION'])
              .where('createdAt', '<=', new Date())
              .orderBy('createdAt', 'desc')
              .onSnapshot((snapshot) => {
                  let newUnreadCount = 0;
                  let notificationsData:Activity[] = [];
                  let notificationsDataForEachClient: {[id:string]: {unread: number, notifications: Activity[]}} = {};
                  snapshot.docs.map((doc) => {
                      if((doc.data().type === "ACTIVITY" && doc.data().message.data !== undefined)){
                          if(clients.includes(doc.data().threadId) || trainersAndAdmins.includes(doc.data().threadId)){
                              if(notificationsDataForEachClient[doc.data().threadId]){
                                  let notifications = notificationsDataForEachClient[doc.data().threadId].notifications;
                                  notifications.push(prepMessage(doc, false));
                                  notificationsDataForEachClient[doc.data().threadId].notifications = notifications;
                                  if(!doc.data().read){
                                      notificationsDataForEachClient[doc.data().threadId].unread++;
                                  }
                              }
                              else{
                                  notificationsDataForEachClient[doc.data().threadId] = {
                                      notifications: [prepMessage(doc, false)],
                                      unread: doc.data().read ? 0 : 1
                                  }
                              }
                          }
                          notificationsData.push(prepMessage(doc, false));
                          if(doc.data().read !== undefined && !doc.data().read){
                            newUnreadCount++;
                          }
                      }
                      else if(doc.data().type === "BIRTHDAY_NOTIFICATION" || doc.data().type === "TRAINER_NOTIFICATION" || doc.data().type === "UNLINK_QUESTION"){
                          if(notificationsDataForEachClient[doc.data().threadId]){
                              let notifications = notificationsDataForEachClient[doc.data().threadId].notifications;
                              notifications.push(prepMessage(doc, true));
                              notificationsDataForEachClient[doc.data().threadId].notifications = notifications;
                              if(!doc.data().readTrainer){
                                  notificationsDataForEachClient[doc.data().threadId].unread++;
                              }
                          }
                          else{
                              notificationsDataForEachClient[doc.data().threadId] = {
                                  notifications: [prepMessage(doc, false)],
                                  unread: doc.data().readTrainer ? 0 : 1
                              }
                          }
                          notificationsData.push(prepMessage(doc, true));
                          if(!doc.data().readTrainer){
                            newUnreadCount++;
                          }
                      }
              });
            for(const client of clients){
                if(!notificationsDataForEachClient[client]){
                    notificationsDataForEachClient[client] = {
                        notifications: [],
                        unread: 0
                    }
                }
            }
              
            for(const user of trainersAndAdmins){
                if(!notificationsDataForEachClient[user]){
                    notificationsDataForEachClient[user] = {
                        notifications: [],
                        unread: 0
                    }
                }
                else{
                    let notifications = notificationsDataForEachClient[user].notifications;
                    notifications.sort((a: Activity, b: Activity) => a.createdAt.getTime() - b.createdAt.getTime());
                    notificationsDataForEachClient[user].notifications = notifications;
                }
            }
              setAllNotifications(notificationsData);
              setUnreadCount(newUnreadCount);
              setNotificationsForEachClient(notificationsDataForEachClient);
            })
        }
    }


    const getTrainersAndAdminsForOrganization = async () => {
        let trainersAndAdmins: string[] = [];
        if(user?.currentOrganization !== undefined){
            const snap = await firestore
                    .collection('organizationUser')
                    .where('orgId', '==', user?.currentOrganization)
                    .where('role', 'in', ['Admin', 'Trainer', 'AssistantTrainer']).get();
            snap.forEach(s => {
                if(s.data().userId !== user?.id){
                    trainersAndAdmins.push(s.data().userId);
                }
            }); 
        }

        return trainersAndAdmins;
    }

    const getClients = async () => {
        let clients: string[] = [];
        if(user?.currentOrganization !== undefined) {
            const snap = await firestore
                .collection('organizationUser')
                .where('orgId', '==', user?.currentOrganization)
                .where('role', '==', 'Client').get();
            snap.forEach(s => {
                clients.push(s.data().userId);
            }); 
        }

        return clients;
      }

    const markAsReadAllNotifications = async () => {
        allNotifications.map(async notification => {
            if(notification.read !== undefined && !notification.read){
                await firestore.collection('activity').doc(notification.id).update({read: true});
            }
            if ((notification.type === 'BIRTHDAY_NOTIFICATION' || notification.type === 'TRAINER_NOTIFICATION') && !notification.readTrainer){
                await firestore.collection('activity').doc(notification.id).update({readTrainer: true});
            }
        });
        setUnreadCount(0);
    }

    const markAsReadNotificationsForClient = async (clientId: string) => {
        if(Object.keys(notificationsForEachClient).length !== 0 && notificationsForEachClient[clientId] && notificationsForEachClient[clientId].unread > 0){
            for(const notification of notificationsForEachClient[clientId].notifications){
                if(notification.read !== undefined && !notification.read){
                    await firestore.collection('activity').doc(notification.id).update({read: true}).then(() => readNotifications())
                }
                if ((notification.type === 'BIRTHDAY_NOTIFICATION' || notification.type === 'TRAINER_NOTIFICATION') && !notification.readTrainer){
                    await firestore.collection('activity').doc(notification.id).update({readTrainer: true}).then(() => readNotifications())
                }
            }
        }
    }

    const deleteFromListOfNotifications = () => {
        readAllNotifications();
    }

    return (
        <NotificationsContext.Provider
          value={{
            allNotifications,
            unreadCount,
            notificationsForEachClient,
            markAsReadAllNotifications,
            markAsReadNotificationsForClient,
            limit,
            setLimit,
            currentLimit,
            setCurrentLimit,
            setUserIdForLoad,
            deleteFromListOfNotifications,
            readAllNotifications
          }}
        >
        {children}
        </NotificationsContext.Provider>
    
      );

};

export const useNotificationsProvider = () => {
    const context = useContext(NotificationsContext);
    if (context === undefined) {
      throw new Error(
        'useNotificationsContext must be used within a NotificationsProvider'
      );
    }
    return context;
  };
