import {  defineMessages } from 'react-intl'

const messages = defineMessages({
  test: {
    id: 'app.test',
    defaultMessage: 'Testing message'
  },
  close: {
    id: 'button.close',
    defaultMessage: 'Close'
  },
  or_close: {
    id: 'button.or_close',
    defaultMessage: 'Or close'
  },
  skip_forget: {
    id: 'button.skip_forget',
    defaultMessage: 'Skip & Forget'
  },
  back: {
    id: 'button.back',
    defaultMessage: 'Back'
  },
  cancel: {
    id: 'button.cancel',
    defaultMessage: 'cancel'
  },
  next: {
    id: 'button.next',
    defaultMessage: 'next'
  },
  submit: {
    id: 'button.submit',
    defaultMessage: 'submit'
  },
  save: {
    id: 'button.save',
    defaultMessage: 'save'
  },
  done: {
    id: 'button.done',
    defaultMessage: 'Done!'
  },
  accept_changes_button: {
    id: 'button.accept_changes',
    defaultMessage: 'Accept changes'
  },
  confirmed: {
    id: 'general.confirmed',
    defaultMessage: 'Yes'
  },
  name: {
    id: 'Name',
    defaultMessage: 'Name'
  },
  rename:{
    id:"Rename",
    defaultMessage:'Rename'
  },
  email: {
    id: 'email',
    defaultMessage: 'Email'
  },
  phone_number: {
    id: 'phone_number',
    defaultMessage: 'Phone Number'
  },
  mandatory_field: {
    id: 'mandatory_field',
    defaultMessage: 'Mandatory field'
  },
  signout_button: {
    id: 'button.signout',
    defaultMessage: 'Sign Out'
  },
  create: {
    id: 'Create',
    defaultMessage: 'Create'
  },
  update: {
    id: 'Update',
    defaultMessage: 'Update'
  },
  apply: {
    id: 'Apply',
    defaultMessage: 'Apply'
  },
  created_at: {
    id: 'created_at',
    defaultMessage: 'Created At'
  },
  manage: {
    id: 'manage',
    defaultMessage: 'Manage'
  },
  delete: {
    id: 'button.delete',
    defaultMessage: 'Delete'
  },
  confirm: {
    id: 'button.confirm',
    defaultMessage: 'Confirm'
  },
  yes: {
    id: 'Yes',
    defaultMessage: 'Yes'
  },
  no: {
    id: 'No',
    defaultMessage: 'No'
  },
  ok: {
    id: 'button.ok',
    defaultMessage: 'OK'
  },
  search_placeholder: {
    id: 'workout.contentfilter.searchfield.placeholder',
    defaultMessage: '  Type at least 3 characters to search'
  },
  total_number_of_organizations: {
    id: 'organization.total_number_of_organizations',
    defaultMessage: 'Total Number of Organizations:'
  },
  save_and_next: {
    id: 'button.save_and_next',
    defaultMessage: 'Save and next'
  },
  address: {
    id: 'address',
    defaultMessage: 'Address'
  },
  required_field: {
    id: 'messages.this_field_is_required',
    defaultMessage: 'This field is required.'
  },
  country: {
    id: 'country',
    defaultMessage: 'Country'
  },
  state_province: {
    id: 'state_province',
    defaultMessage: 'State/Province'
  },
  first_name: {
    id: 'client.first_name',
    defaultMessage: 'First Name'
  },
  last_name: {
    id: 'client.last_name',
    defaultMessage: 'Last Name'
  },
  kcal: {
    id: 'workout.kcal',
    defaultMessage: "KCal"
  },
  no_limit: {
    id: 'dateTimeFilter.NO_LIMIT',
    defaultMessage: 'No date limit'
  },
  days_1: {
      id: 'dateTimeFilter.DAYS_1',
      defaultMessage: ' 1 Day'
  },
  days_7: {
      id: 'dateTimeFilter.DAYS_7',
      defaultMessage: ' 7 Days'
  },
  days_10: {
      id: 'dateTimeFilter.DAYS_10',
      defaultMessage: ' 10 Days'
  },
  days_30: {
      id: 'dateTimeFilter.DAYS_30',
      defaultMessage: ' 30 Days'
  },
  days_60: {
      id: 'dateTimeFilter.DAYS_60',
      defaultMessage: ' 60 Days'
  },
  days_90: {
      id: 'dateTimeFilter.DAYS_90',
      defaultMessage: ' 90 Days'
  },
  today: {
    id: 'today',
    defaultMessage: 'Today (last 24h)'
  },
  last_7_days: {
    id: 'last_7_days',
    defaultMessage: 'Last 7 days'
  },
  status_all: {
    id: 'status_all',
    defaultMessage: 'ALL - All'
  },
  fr_free: {
    id: 'fr_free',
    defaultMessage: 'FR - Free'
  },
  std_standard: {
    id: 'std_standard',
    defaultMessage: 'STD - Standard'
  },
  rb_rebrand: {
    id: 'rb_rebrand',
    defaultMessage: 'RB - Rebrand'
  },
  py_paying: {
    id: 'py_paying',
    defaultMessage: 'PY - Paying (3+ Clients)'
  },
  prm_premium: {
    id: 'prm_premium',
    defaultMessage: 'PRM - Premium (15-29 Clients)'
  },
  gld_gold: {
    id: 'gld_gold',
    defaultMessage: 'GLD - Gold (30-49 Clients)'
  },
  slv_silver: {
    id: 'slv_silver',
    defaultMessage: 'SLV - Silver (7-14 Clients)'
  },
  plt_platinum: {
    id: 'plt_platinum',
    defaultMessage: 'PLT - Platinum (50+ Clients)'
  },

  clients_0: {
    id: 'clients_0',
    defaultMessage: '0 Clients'
  },
  clients_1_2: {
    id: 'clients_1_2',
    defaultMessage: '1-2 Clients'
  },
  clients_3_9: {
    id: 'clients_3_9',
    defaultMessage: '3-9 Clients'
  },
  clients_10_19: {
    id: 'clients_10_19',
    defaultMessage: '10-19 Clients'
  },
  clients_20_29: {
    id: 'clients_20_29',
    defaultMessage: '20-29 Clients'
  },
  clients_30_39: {
    id: 'clients_30_39',
    defaultMessage: '30-39 Clients'
  },
  clients_40_49: {
    id: 'clients_40_49',
    defaultMessage: '40-49 Clients'
  },
  clients_50_79: {
    id: 'clients_50_79',
    defaultMessage: '50-79 Clients'
  },
  clients_80_99: {
    id: 'clients_80_99',
    defaultMessage: '80-99 Clients'
  },
  clients_100: {
    id: 'clients_100',
    defaultMessage: '100+ Clients'
  },
  changes_saved: {
    id: 'messages.changes_saved',
    defaultMessage: ' Changes saved!'
  },
  archive: {
    id: 'button.archive',
    defaultMessage: 'Archive'
  },
  archived:{
    id: 'button.archived',
    defaultMessage:"Archived"
  },
  unarchive: {
    id: 'button.unarchive',
    defaultMessage: 'Unarchive'
  },
  unarchived:{
    id:'button.unarchived',
    defaultMessage:'Unarchived'
  },
  last_sign_in_options_filter: {
    id: 'dateTimeFilter.last_sign_in_options_filter',
    defaultMessage: 'Last Sign In'
  },
  back_to_sign_in: {
    id: 'button.back_to_sign_in',
    defaultMessage: 'Back to Sign in'
  },
  good_for_and_counter_productive_for_can_not_have_the_same_values: {
    id: 'nutrition_plan.good_for_and_counter_productive_for_can_not_have_the_same_values',
    defaultMessage: 'Good for and counterproductive for can not have the same values'
  },
  no_items_filter: {
    id: 'messages.no_items_filter',
    defaultMessage: 'No items match your filter options.'
  },
  exercises_no_items_filter: {
    id: 'messages.exercises_no_items_filter',
    defaultMessage: 'There are no Archived Exercises so far. When You Archive an Exercise, it will be shown in the list under this filter.'
  },
  workouts_no_items_filter: {
    id: 'messages.workouts_no_items_filter',
    defaultMessage: 'There are no Archived Workouts so far. When You Archive an Workout, it will be shown in the list under this filter.'
  },
  programs_no_items_filter: {
    id: 'messages.programs_no_items_filter',
    defaultMessage: 'There are no Archived Programs so far. When You Archive an Program, it will be shown in the list under this filter.'
  },
  groceries_no_items_filter: {
    id: 'messages.groceries_no_items_filter',
    defaultMessage: 'There are no Archived Groceries so far. When You Archive a Grocery, it will be shown in the list under this filter.'
  },
  meals_no_items_filter: {
    id: 'messages.meals_no_items_filter',
    defaultMessage: 'There are no Archived Meals so far. When You Archive a Meal, it will be shown in the list under this filter.'
  },
  nutritionPlan_no_items_filter: {
    id: 'messages.nutritionPlan_no_items_filter',
    defaultMessage: 'There are no Archived Nutrition Plans so far. When You Archive a Nutrition, it will be shown in the list under this filter.'
  },
  password_must_contain_at_least_six_characters: {
    id: 'messages.password_must_contain_at_least_six_characters',
    defaultMessage: 'Password must contain at least 6 characters.'
  },
  password_and_confirm_password_must_be_match: {
    id: 'messages.password_and_confirm_password_must_be_match',
    defaultMessage: 'Password and confirm password must be match.'
  },
  program_week: {
    id: 'program.week',
    defaultMessage: 'Week'
  },
  save_changes: {
    id: 'button.save_changes',
    defaultMessage: 'Save Changes'
  },
  change_password: {
    id: 'trainer_profile.change_password',
    defaultMessage: 'Change Password'
  },
  confirm_password: {
    id: 'organization.confirm_password',
    defaultMessage: 'Confirm Password'
  },
  error_loading: {
    id: 'messages.error_loading',
    defaultMessage: 'There was an error loading this page. Please refresh the page. If the problem persists, please contact support.'
  },
  exercises: {
    id: 'Exercises',
    defaultMessage: 'Exercises'
  },
  workouts: {
    id: 'Workouts',
    defaultMessage: 'Workouts'
  },
  programs: {
    id: 'Programs',
    defaultMessage: 'Programs'
  },
  organizations: {
    id: 'Organizations',
    defaultMessage: 'Organizations'
  },
  remove: {
    id: 'button.remove',
    defaultMessage: 'Remove'
  },
  add_the_basic_details: {
    id: 'nutrition_plan.add_the_basic_details',
    defaultMessage: 'Add basic details and a video.'
  },
  title: {
    id: 'Title',
    defaultMessage: 'Title'
  },
  description: {
    id: 'Description',
    defaultMessage: 'Description'
  },
  success: {
    id: 'messages.success',
    defaultMessage: 'Changes saved successfully!'
  },
  error: {
      id: 'messages.error',
      defaultMessage: 'Changes not saved! Please try again. If the problem persists, please contact support.'
  },
  link: {
    id: 'messages.link',
    defaultMessage: 'Link'
  },
  edit: {
    id: 'messages.edit',
    defaultMessage: 'Edit'
  },
  header_note: {
    id: 'workout.edit.header_note',
    defaultMessage: 'Please use dot(.) as decimal separator for number fields'
  },
  billing_details: {
    id: 'billing.billing_details',
    defaultMessage: 'Billing Details'
  },
  role: {
    id: 'Role',
    defaultMessage: 'Role'
  },
  all: {
    id: 'premium.all',
    defaultMessage: 'ALL'
  }, 
  none: {
    id: 'premium.none',
    defaultMessage: 'NONE'
  },
  january: {
    id: 'months.january',
    defaultMessage: 'January'
  },
  february: {
    id: 'months.february',
    defaultMessage: 'February'
  },
  march: {
    id: 'months.march',
    defaultMessage: 'March'
  },
  april: {
    id: 'months.april',
    defaultMessage: 'April'
  },
  may: {
    id: 'months.may',
    defaultMessage: 'May'
  },
  june: {
    id: 'months.june',
    defaultMessage: 'June'
  },
  july: {
    id: 'months.july',
    defaultMessage: 'July'
  },
  august: {
    id: 'months.august',
    defaultMessage: 'August'
  },
  september: {
    id: 'months.september',
    defaultMessage: 'September'
  },
  october: {
    id: 'months.october',
    defaultMessage: 'October'
  },
  november: {
    id: 'months.november',
    defaultMessage: 'November'
  },
  december: {
    id: 'months.december',
    defaultMessage: 'December'
  },
  number: {
    id: 'number',
    defaultMessage: 'Number'
  },
  clients: {
    id: 'Clients',
    defaultMessage: 'Clients'
  },
  delete_client: {
    id: 'delete_client',
    defaultMessage: 'Delete Client'
  },
  can_add_new_clients: {
    id: 'Can Add New Clients?',
    defaultMessage: 'Can Add New Clients?'
  },
  copy_org_content: {
    id: 'copy_org_content',
    defaultMessage: 'Can Copy ORG Content'
  },
  actions: {
    id: 'Actions',
    defaultMessage: 'Actions'
  },
  trainer: {
    id: 'role.trainer',
    defaultMessage: 'Trainer'
  },
  sign_in: {
    id: 'login_page.sign_in',
    defaultMessage: 'Sign In'
  },
  register: {
    id: 'button.register',
    defaultMessage: 'Register'
  },
  email_wl_subject: {
    id: 'profile_image.email_wl_subject',
    defaultMessage: '{name} - Contact Me'
  },
  email_WL_subject_message: {
      id: 'messages.email_WL_subject',
      defaultMessage: '{name} - Contact Me'
  },
  email_WL_body: {
      id: 'messages.email_WL_body',
      defaultMessage: 'Hello, I have seen the content on your profile on the {netName} and would like to get some more information about the services you offer?'
  },
  error_saving_data: {
    id: 'messages.error_saving_data',
    defaultMessage: 'There was an error saving your data.'
  },
  are_you_sure: {
    id: 'messages.are_you_sure',
    defaultMessage: 'Are you sure you want to change your Subscription Plan?'
  },
  notification_center: {
    id: 'notification_center',
    defaultMessage: 'Notification center'
  },
  type: {
    id: 'messages.type',
    defaultMessage: 'Type'
  },
  group: {
    id: 'group',
    defaultMessage: 'Group'
  },
  schedule_1: {
    id: 'schedule_1',
    defaultMessage: 'Schedule - Title'
  },
  content_1: {
    id: 'content_1',
    defaultMessage: 'Content - Exercises'
  },
  content_2: {
    id: 'content_2',
    defaultMessage: 'Content - Workouts'
  },
  content_3: {
    id: 'content_3',
    defaultMessage: 'Content - Programs'
  },
  nutrition_1: {
    id: 'nutrition_1',
    defaultMessage: 'Nutrition - Groceries'
  },
  nutrition_2: {
    id: 'nutrition_2',
    defaultMessage: 'Nutrition - Meals'
  },
  nutrition_3: {
    id: 'nutrition_3',
    defaultMessage: 'Nutrition - NutritionPlans'
  },
  configuration_1: {
    id: 'configuration_1',
    defaultMessage: 'Configuration - Title'
  },
  my_organization_1: {
    id: 'my_organization_1',
    defaultMessage: 'My Organization - Title'
  },
  clients_1: {
    id: 'clients_1',
    defaultMessage: 'Clients - ClientGroups'
  },
  clients_2: {
    id: 'clients_2',
    defaultMessage: 'Clients - AddClient'
  },
  notification_center_1: {
    id: 'notification_center_1',
    defaultMessage: 'Notification Center'
  },
  profile_organization_profile_1: {
    id: 'profile_organization_profile_1',
    defaultMessage: 'OrgProfile && Profile - Rebrand'
  },
  profile_organization_profile_2: {
    id: 'profile_organization_profile_2',
    defaultMessage: 'OrgProfile && Profile - SocialMedial'
  },
  stripe_connect_1: {
    id: 'stripe_connect_1',
    defaultMessage: 'Stripe Connect 1'
  },
  marketplace_1: {
    id: 'marketplace_1',
    defaultMessage: 'Marketplace 1'
  },
  client_calendar_1: {
    id: 'client_calendar_1',
    defaultMessage: 'Copy Training - Client Calendar'
  },
  progress_photos_1: {
    id: 'progress_photos_1',
    defaultMessage: 'Progress Photos'
  },
  body_skinfold_1: {
    id: 'body_skinfold_1',
    defaultMessage: 'Body Skinfold Measurements'
  },
  documents_1: {
    id: 'documents_1',
    defaultMessage: 'Documents 1'
  },
  insert_exercises_1: {
    id: 'insert_exercises_1',
    defaultMessage: 'Insert Exercises YT'
  },
  copy_template_1: {
    id: 'copy_template_1',
    defaultMessage: 'Copy Template'
  },
  edit_exercise_1: {
    id: 'edit_exercise_1',
    defaultMessage: 'Edit Exercise'
  },
  edit_workout_1: {
    id: 'edit_workout_1',
    defaultMessage: 'Edit Workout'
  },
  edit_program_1: {
    id: 'edit_exercise_1',
    defaultMessage: 'Edit Program'
  },
  general: {
    id: 'general',
    defaultMessage: "General - ListedInTutorials"
  },
  assign_program_to_group: {
    id: 'assign_program_to_group',
    defaultMessage: 'Assign to group the program'
  },
  add_to_group_button: {
    id: 'add_to_group_button',
    defaultMessage: 'Add to clients group'
  }
})

export default messages;
